import React, { useEffect, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import GoogleMap from 'components/googlemap';
import './SearchBeach.css';
import cityData from 'images/current.city.list.json'; // JSON 파일을 import
// import mapStyle from 'images/snazzymaps-com-light-monochrome.json'

const Item3 = () => {
  const onMapLoad = (map) => {
    const bounds = new window.google.maps.LatLngBounds();
    let nearbycities = [];
    map.addListener('idle', () => {
      fetchVisibleCities(map)
        .then(cities => {
          nearbycities = cities;
          const sliced_cities = nearbycities.slice(0, 2);
          displayWeatherInfo(map, sliced_cities, bounds);
        })
        .catch(error => {
          console.error('fetchVisibleCities error:', error);
          alert('No cities found in the current map view.');
        });
    });
  };

  const fetchVisibleCities = (map) => {
    return new Promise((resolve, reject) => {
      const service = new window.google.maps.places.PlacesService(map);
      const bounds = map.getBounds();
      const request = {
        bounds: bounds,
        type: 'locality'
      };

      service.nearbySearch(request, (results, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          console.log('google place(nearbySearch)', results);

          const placeList = results.map(place => ({
            name: place.name,
            lat: place.geometry.location.lat(),
            lon: place.geometry.location.lng()
          }));
          resolve(placeList);
        } else {
          console.error(`PlacesServiceStatus not OK: ${status}`);
          reject(new Error(`PlacesServiceStatus not OK: ${status}`));
        }
      });
    });
  };

  const fetchWeatherData = async (name, lat, lon) => {
    const apiKey = process.env.REACT_APP_OPENWEATHERMAP_API_KEY;
    const response = await fetch(`https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&appid=${apiKey}&units=metric`);
    const data = await response.json();
    console.log(`api.openweathermap(${name}, ${lat}:${lon}):`, data);
    return data;
  };

  const displayWeatherInfo = async (map, filtered_city, bounds) => {

    for (const city of filtered_city) {
      const data = await fetchWeatherData(city.name, city.lat, city.lon);
      const position = { lat: city.lat, lng: city.lon };
      createCustomMarker(map, position, city, data, bounds);
    }

    // 지도를 모든 마커가 보이도록 조정
    // map.fitBounds(bounds);
  };

  const createCustomMarker = (map, position, city, data, bounds) => {
    class CustomMarker extends window.google.maps.OverlayView {
      constructor(position, city, data) {
        super();
        this.position = position;
        this.city = city;
        this.data = data;
        this.div = null;
      }

      onAdd() {
        const div = document.createElement('div');
        div.className = 'custom-marker';
        div.innerHTML = `
        <div class="marker-content">
          <div class="marker-header">
            <span class="marker-city">${this.city.name} </span>
          </div>
          <div class="marker-header">
            <img src="https://img.icons8.com/clouds/100/000000/rain.png" class="marker-icon" alt="rain-icon"/>
            <span>${this.data.weather[0].description}</span>
          </div>
          <div class="marker-temp">
            ${this.data.main.temp}°C
          </div>
          <div class="marker-arrow"></div>
        </div>
        `;

        this.div = div;

        const panes = this.getPanes();
        panes.overlayImage.appendChild(div);
      }

      draw() {
        const overlayProjection = this.getProjection();
        const position = overlayProjection.fromLatLngToDivPixel(this.position);

        const div = this.div;
        if (div) {
          div.style.left = position.x + 'px';
          div.style.top = position.y + 'px';
        }
      }

      onRemove() {
        if (this.div) {
          this.div.parentNode.removeChild(this.div);
          this.div = null;
        }
      }
    }

    const customMarker = new CustomMarker(position, city, data);
    customMarker.setMap(map);
    bounds.extend(position);
  }

  return <GoogleMap onMapLoad={onMapLoad} />;
};

export default Item3;